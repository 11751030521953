import Link from "next/link";
import { Internationalization } from "../../Internationalization/Internationalization";
import links from "client/config/links";
import { Translate } from "components/common/Translate/Translate";
import translatedRoutedSlugs from "translatedRouteSlugs";
import { useRouter } from "next/router";

export interface LanguageProps {
    url: keyof typeof translatedRoutedSlugs;
    urlSlugVariables?: Record<string, string>;
}

const VERSION = `v.${process.env["NEXT_PUBLIC_APPLICATION_VERSION"]}`;

export const Footer = ({ url, urlSlugVariables }: LanguageProps) => {
    const { locale } = useRouter();
    return (
        <div className="flex flex-col min-w-0">
            {/* bottom bar */}
            <div className="flex flex-col gap-4 py-8 text-black bg-gray-100 lg:flex-row-reverse lg:items-center dark:bg-darkSurface-200 dark:text-white">
                <div className="flex flex-col w-full gap-4 px-8 lg:flex-row-reverse">
                    {/* internationalization */}
                    <div>
                        <Internationalization url={url} urlSlugVariables={urlSlugVariables} />
                    </div>
                    <ul className="flex flex-col gap-4 lg:flex-row">
                        {links.map((link, index) => {
                            if (link.link === "/contact") {
                                return (
                                    <li key={index}>
                                        <a
                                            href="https://feedback.userreport.com/491ee207-f8e0-47ae-8773-9769105f0b65/"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                // @ts-ignore
                                                _urq.push(["Feedback_Open"]);
                                            }}
                                            aria-label={"common:contact-us-aria-label"}
                                        >
                                            <Translate keyName={link.title} />
                                        </a>
                                    </li>
                                );
                            }
                            const translatedRoute = (translatedRoutedSlugs as any)[link.link]?.[locale ?? "en"];
                            const href =
                                link.link.includes("http")
                                    ? link.link
                                    : (translatedRoute !== null ? "/" + translatedRoute : null) ??
                                    link.link
                            return (
                                <li key={index}>
                                    <Link
                                        href={href}
                                    >
                                        <a>
                                            <Translate keyName={link.title} />
                                        </a>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {/* copyright */}
                <div className="px-8 py-4 border-t border-t-slate-200 dark:border-t-darkSurface-400 lg:border-t-0 whitespace-nowrap">
                    {`© ${new Date().getFullYear()} ImageResizer `}{" "}
                    <Link href={`/changelogs#${VERSION}`}>
                        <a target="_blank" className="text-irBlue-600 dark:text-irBlue-200">{VERSION}</a>
                    </Link>{". "}
                    <Translate keyName="all-rights-reserved" />
                    {"."}
                </div>
            </div>
        </div>
    );
};
