import tools from "client/config/tools";
import { Translate } from "components/common/Translate/Translate";
import Link from "next/link";
import { useRouter } from "next/router";
import { MouseEvent } from "react";
import translatedRoutedSlugs from "translatedRouteSlugs";

export const FEEDBACK_FORUM_LINK = "https://feedback.userreport.com/491ee207-f8e0-47ae-8773-9769105f0b65/";
export const onFeedBackForumLinkClick = (event: MouseEvent) => {
    event.preventDefault();
    // @ts-ignore
    _urq.push(["Feedback_Open"]);
};

export const Tools = () => {
    const { locale } = useRouter();
    return (
        <ul className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-5 px-8 lg:px-16 mt-auto border-t-slate-200 dark:border-t-darkSurface-600 border-t py-8 lg:py-16 gap-8 text-black dark:text-white">
            {tools.map((info, index) => {
                return (
                    <li key={index} className="flex flex-col gap-4 text-center">
                        <div className="font-bold">
                            <Translate keyName={info.header} />
                        </div>
                        <ul className="flex flex-col gap-4">
                            {info.links.map((link, index) => {
                                if (link.link === "/contact-us") {
                                    return (
                                        <li key={index}>
                                            <a href={FEEDBACK_FORUM_LINK} onClick={onFeedBackForumLinkClick}>
                                                <Translate keyName={link.title} />
                                            </a>
                                        </li>
                                    );
                                }
                                const translatedRoute = (translatedRoutedSlugs as any)[link.link]?.[locale ?? "en"];
                                const href =
                                    link.link.includes("http")
                                        ? link.link
                                        : (translatedRoute !== null ? "/" + translatedRoute : null) ??
                                        link.link

                                return (
                                    <li key={index}>
                                        <Link
                                            href={href}
                                        >
                                            <a target={link.link.includes("http") ? "_blank" : "_self"}>
                                                <Translate keyName={link.title} />
                                            </a>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </ul>
    );
};
